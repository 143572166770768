html,
body {
  @apply bg-white p-0 m-0;
}

html {
  @apply m-auto font-body;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  scroll-padding-top: theme("spacing.bar");
}

body {
  @apply flex flex-col font-body text-base text-gray-dark;

  font-weight: 400;
  line-height: 1.5;
  scroll-behavior: smooth;
  overflow-anchor: none; /* Disable scroll position staying at bottom of list in webkit */
}

button,
input,
optgroup,
select,
textarea {
  @apply font-body;
}

a {
  @apply text-blue;
}

a:hover,
a:active,
a:focus {
  @apply text-black;
}

button {
  @apply cursor-pointer;
}

img {
  height: auto;
  max-width: 100%;
  image-orientation: from-image;
}

span {
  font-style: inherit;
  font-weight: inherit;
}

hr {
  @apply bg-gray-border border-none h-px block;
}

main,
footer {
  overflow-wrap: anywhere;
  word-break: break-word;
}

audio::-webkit-media-controls {
  @apply px-1;
}

audio::-webkit-media-controls-enclosure {
  @apply rounded-lg border-solid border-px border-blue-200 bg-white text-gray-interactive;
}

audio::-webkit-media-controls-panel {
  @apply px-6;
}

.martor-toolbar {
  z-index: 1 !important;
}
