/* Filter controls */
@layer components {
  .mobile-filter-toggle {
    @apply button h-10 w-10 min-w-40 min-h-40 m-0 ml-1 p-0 flex items-center justify-center grow-0 text-blue;
  }

  .mobile-filter-toggle:hover,
  .mobile-filter-toggle:focus,
  .mobile-filter-toggle:active {
    @apply text-black;
  }

  .mobile-filter-toggle[aria-expanded="true"],
  .mobile-filter-toggle[aria-expanded="true"]:hover,
  .mobile-filter-toggle[aria-expanded="true"]:focus,
  .mobile-filter-toggle[aria-expanded="true"]:active {
    @apply bg-yellow border-yellow text-black;
  }

  #filter-create-links {
    @apply hidden;
  }

  .expanded > #filter-create-links {
    @apply block;
  }

  .filter-fields-header .field-group:first-child {
    @apply flex flex-row items-center flex-wrap min-h-40 m-0 relative;
  }

  .filter-fields-header .field-group:first-child .field:first-child {
    @apply min-w-40 min-h-40 flex-grow mb-0;
  }

  .filter-fields-header .search {
    @apply bg-white;
  }

  .filter-fields-body select.input {
    @apply text-blue;
  }

  .filter-fields-body .field:not(:last-child) {
    @apply mb-1;
  }

  .scroll-to-top button {
    @apply opacity-0;

    transition: 0.1s opacity ease-in-out;
  }

  .scroll-to-top button.visible {
    @apply opacity-100;
  }

  @screen mobile {
    .filter-fields-body {
      @apply my-0 mx-5 -mt-6 rounded-md border-px border-gray-border bg-white shadow-dropdown relative;
    }
  }
}

/* Filter results */
@layer components {
  .filter-result-row {
    @apply min-h-13 p-3 pb-0 relative flex flex-row flex-wrap cursor-pointer;
  }

  .filter-result-row:focus {
    @apply bg-yellow-rowhighlight;
  }

  .filter-result-row:not([aria-hidden]) .filter-result-row-divider {
    @apply flex order-10 m-3 mb-0 h-px bg-gray-border w-full;
  }

  [role="button"]:focus + .collapse-row[aria-hidden="false"] {
    @apply bg-yellow-rowhighlight;
  }

  .collapse-row[aria-hidden] {
    @apply min-h-0 p-0 border-transparent pointer-events-none;

    transition: 0.2s all ease-in-out;
  }

  .collapse-row[aria-hidden="true"] {
    @apply max-h-0 opacity-0 overflow-hidden;
  }

  .collapse-row[aria-hidden="false"] {
    @apply shadow-row max-h-250vh pointer-events-auto;
  }

  .search-highlights {
    overflow-wrap: anywhere;
  }

  .search-highlights em {
    @apply bg-yellow-highlight;

    font-style: normal;
  }

  .role-icon-link {
    @apply flex items-center justify-center relative h-44 w-44 rounded bg-yellow text-black;

    border: 3px solid transparent;
    font-size: 20px;
  }

  .role-icon-link:active,
  .role-icon-link:focus,
  .role-icon-link:hover {
    @apply border-gray-400;
  }

  .role-icon-link:active .icon-tooltip,
  .role-icon-link:focus .icon-tooltip,
  .role-icon-link:hover .icon-tooltip {
    @apply block;
  }

  .role-icon-link.Live-tweeting {
    @apply text-white;

    background-color: #1da1f2;
  }

  .role-icon-link .icon-tooltip {
    @apply absolute p-2 rounded bg-gray-dark text-white hidden whitespace-nowrap z-1;

    top: 48px;
    font-size: 13px;
  }
}
