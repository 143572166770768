@font-face {
  font-family: "ValueSerif";
  src: url("https://assets-shared.documenters.org/fonts/value-serif-bold.woff2")
      format("woff2"),
    url("https://assets-shared.documenters.org/fonts/value-serif-bold.woff")
      format("woff"),
    url("https://assets-shared.documenters.org/fonts/value-serif-bold.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
