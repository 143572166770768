@layer components {
  .content {
    @apply block leading-tight;

    overflow-wrap: anywhere;
    word-break: break-word;
  }
}

.content p:not(:last-child),
.content ul:not(:last-child),
.content blockquote:not(:last-child) {
  @apply mb-6;
}

.content li + li {
  @apply mt-2;
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  @apply font-bold;
}

.content h1 {
  @apply text-xxl mt-5 mb-7;
}

.content h2 {
  @apply text-xl mt-4 mb-6;
}

.content h3 {
  @apply text-lg mt-3 mb-5;
}

.content h1:first-child,
.content h2:first-child,
.content h3:first-child {
  @apply mt-0;
}

.content ul {
  @apply mt-4;

  padding-inline-start: theme("spacing.6");
  list-style: disc outside;
}

.content.event-reg {
  max-height: calc(100vh - 90px);
}
