@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .overflow-scroll-touch {
    -webkit-overflow-scrolling: touch;
  }
  .scrollbar-hidden {
    scrollbar-width: none;
  }
  .scrollbar-hidden::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  .visually-hidden-focusable:not(:focus) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .only-display-expanded {
    @apply hidden;
  }

  .expanded .only-display-expanded,
  .only-display-expanded.expanded {
    @apply block z-10;
  }

  .link-text-color {
    @apply text-blue;
  }
  .link-text-color:hover,
  .link-text-color:active,
  .link-text-color:focus {
    @apply text-black;
  }

  .ellipsis-overflow {
    @apply block whitespace-nowrap;

    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .min-h-full {
    min-height: calc(100vh - theme("inset.nav"));
  }

  @screen md {
    .min-h-full {
      min-height: calc(100vh - theme("inset.nav-md"));
    }
  }

  .clamp-lines-3 {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .text-red-active:hover,
  .text-red-active:active,
  .text-red-active:focus {
    @apply text-red;
  }

  .border-r-transparent {
    border-right-color: transparent;
  }

  .hanging-punctuation-first {
    hanging-punctuation: first;
  }

  .markdown-formatted p {
    margin: 15px 0px;
  }

  .markdown-formatted p:first-child {
    margin-top: 0px;
  }

  .markdown-formatted ul,
  .markdown-formatted ol {
    margin-left: 12px;
  }

  .markdown-formatted ul {
    list-style-type: circle;
  }

  .markdown-formatted ol {
    list-style-type: decimal;
  }
}
