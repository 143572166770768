@media only screen and (min-width: 560px) {
  #template-edit .back-btn {
    width: 132px;
    padding-left: 28px;
    border-right: 1px solid #d6d5d5;
  }

  #template-edit .template-email-name {
    max-height: 48px;
    width: calc(100% - 145px);
  }

  #template-edit .template-email-name > label {
    flex: 0 0 120px;
  }

  #template-edit .template-email-name > span {
    line-height: 24px;
  }
}

@media only screen and (min-width: 768px) {
  #messaging .section-box {
    border: 1px solid #d6d5d5;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }

  #messaging .section-header {
    @apply px-7 pt-6 pb-6 flex flex-row flex-wrap;
  }

  #messaging .section-row {
    @apply px-7;
    border: none;
    border-bottom: 1px solid #d6d5d5;
    border-radius: 0;
    margin: 0;
  }

  #messaging .section-header .group-col {
    width: 37.5%;
    padding: 0;
    margin: 0;
  }

  #messaging .section-header .stats-cols {
    width: 62.5%;
  }

  #messaging .enable-btn {
    position: relative;
    top: 0;
    left: 0;
  }

  #template-edit {
    padding-bottom: 0px;
  }
}

@media only screen and (min-width: 1024px) {
  #messaging .section-header .group-col {
    width: 50%;
  }

  #messaging .section-header .stats-cols {
    width: 50%;
  }
}
