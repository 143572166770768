@layer components {
  .dropdown {
    @apply inline-flex relative;

    vertical-align: top;
  }

  .dropdown-trigger {
    @apply h-full flex items-center;
  }

  .dropdown-menu {
    @apply hidden absolute left-0 top-full z-50 p-0 m-0 rounded-md border-px border-gray-border;

    min-width: 230px;
  }

  .dropdown-content {
    @apply bg-white rounded-md p-0 m-0 shadow-dropdown;
  }

  .dropdown-item {
    @apply block relative whitespace-nowrap w-full px-6 py-4 text-blue text-left;
  }
  .dropdown-item:hover,
  .dropdown-item:focus,
  .dropdown-item:active {
    @apply text-black;
  }

  .dropdown-item:first-child {
    @apply rounded-t-lg;
  }
  .dropdown-item:last-child {
    @apply rounded-b-lg;
  }
}
