@layer components {
  .embed-form .field-wrapper {
    @apply mb-6 px-4 w-full;
  }

  .embed-form .field-input-wrapper {
    @apply mt-2;
  }

  .embed-form label {
    @apply text-sm text-gray-interactive;
  }

  .embed-form .help,
  .embed-form .field-wrapper.timespan_type::after {
    @apply mt-2 text-sm text-gray-450;
  }

  .embed-form input,
  .embed-form textarea {
    @apply w-full px-5 py-3 border-px border-gray-border rounded-md bg-white;
  }

  .embed-form input[type="checkbox"] {
    @apply p-0 min-w-0 w-7 min-h-0 h-7;
  }

  .embed-form input::placeholder {
    @apply text-gray-450;
  }

  .embed-form select.input {
    @apply bg-white;
  }

  .embed-form .field.radioselect .control {
    @apply px-0 flex flex-wrap;
  }

  .embed-form .field.radioselect label {
    @apply mr-9 flex items-center;
  }

  .embed-form .field.radioselect label > input {
    @apply mr-4 p-0 min-w-auto min-h-auto w-7 h-7 rounded-full;
  }

  .embed-form .field.radioselect label > input:checked::after {
    @apply m-auto inline-block w-5 h-5 rounded-full bg-blue;
    content: "\A";
  }

  .embed-form .field.radioselect label > span {
    @apply text-base text-black;
  }

  .embed-form .field-wrapper.timespan_type {
    @apply -mt-4;
  }

  .embed-form .field-wrapper.timespan_type .field-input-wrapper {
    @apply my-5;
  }

  .embed-form
    .field-wrapper.timespan_type:has(input[value="Rolling"]:checked)
    ~ .field-wrapper.start_date,
  .embed-form
    .field-wrapper.timespan_type:has(input[value="Rolling"]:checked)
    ~ .field-wrapper.end_date,
  .embed-form
    .field-wrapper.timespan_type:has(input[value="Fixed"]:checked)
    ~ .field-wrapper.timespan_label,
  .embed-form
    .field-wrapper.timespan_type:has(input[value="Fixed"]:checked)
    ~ .field-wrapper.timespan_count,
  .embed-form
    .field-wrapper.timespan_type:has(input[value="Fixed"]:checked)
    ~ .field-wrapper.timespan_unit {
    @apply hidden;
  }

  .embed-form
    .field-wrapper.timespan_type:has(input[value="Rolling"]:checked)::after {
    content: "Rolling means that the embed will continue updating with new data from Documenters.org.";
  }

  .embed-form
    .field-wrapper.timespan_type:has(input[value="Fixed"]:checked)::after {
    content: "Fixed means that only reporting from a set period of time will be displayed in your embed.";
  }

  .embed-form .field-wrapper.timespan_label {
    @apply mb-0;
  }

  .embed-form .field-wrapper.timespan_label .field {
    @apply min-h-0;
  }

  .embed-form .field-wrapper.timespan_label .field-input-wrapper {
    @apply m-0;
  }

  .embed-form .field-wrapper.timespan_label input {
    @apply hidden;
  }

  .embed-form .field-wrapper.timespan_count {
    @apply w-14;
  }

  .embed-form .field-wrapper.timespan_count .error {
    @apply block;

    width: calc(100vw - 48px);
  }

  .embed-form .field-wrapper.timespan_unit {
    @apply w-15;
  }

  .embed-form .field-wrapper.start_date,
  .embed-form .field-wrapper.end_date {
    @apply w-1/2;
  }

  .embed-form .delete-keyword {
    @apply bg-transparent;
  }

  .embed-form .delete-keyword::before {
    @apply w-6 bg-gray-dark;
  }

  .embed-form .delete-keyword::after {
    @apply h-6 bg-gray-dark;
  }

  #copy-btn {
    width: 170px;
  }

  #copy-btn:after {
    content: "Copy embed code";
  }

  #copy-btn:disabled {
    @apply bg-black font-normal;
  }

  #copy-btn:disabled:after {
    content: "Copied to clipboard.";
  }
}
