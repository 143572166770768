#docsy .st0 {
  fill: #b7693e;
}
#docsy .st1 {
  fill: #ffffff;
}
#docsy .st2 {
  fill: url(#SVGID_1_);
}
#docsy .st3 {
  fill: #606060;
}
#docsy .st4 {
  fill: #824d33;
}

g.documenters,
path.documenters {
  stroke: none;
  fill-rule: nonzero;
  fill-opacity: 1;
}
g.powered-by,
path.powered-by {
  stroke: none;
  fill-rule: nonzero;
  fill: rgb(58.247375%, 58.532715%, 58.346558%);
  fill-opacity: 1;
}
g.city-bureau,
path.city-bureau {
  stroke: none;
  fill-rule: nonzero;
  fill: rgb(92.980957%, 11.471558%, 14.03656%);
  fill-opacity: 1;
}

svg:not(:root).svg-inline--fa {
  box-sizing: border-box;
}
