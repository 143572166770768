/* Root Variables */
:root {
  --banner-height: 58px;
}

/* Main Page with Banner Styles */
html:has([role="banner"]) {
  padding-top: var(--banner-height);
}

html:has(#nav-top.active) [role="banner"] {
  @apply bg-yellow;
}

html:has([role="banner"]) #nav-top {
  @apply border-t-px;
  top: var(--banner-height);
}

html:has([role="banner"]) #nav-bottom-fixed {
  top: calc(var(--banner-height) + theme("inset.nav-sm"));
}

@screen md {
  html:has([role="banner"]) #nav-bottom-fixed {
    top: calc(var(--banner-height) + theme("inset.nav-md"));
  }
}

/* Banner Styles */
[role="banner"] {
  @apply bg-yellow-light text-tiny;
  height: var(--banner-height);
}

@screen sm {
  [role="banner"] {
    @apply text-base;
  }
}
