/* Field Wrapper Styles */
.attachment-form form .field-wrapper {
  @apply border-none pb-0;
}

/* Checkbox Styles */
.attachment-form input[type="checkbox"] {
  @apply min-w-0 min-h-0 w-7 h-7 rounded-md bg-white;
}

.attachment-form input[type="checkbox"]:checked {
  @apply bg-blue;
}

.attachment-form input[type="checkbox"]:checked::after {
  @apply text-sm text-white font-bold;
  content: "\2713";
}

/* Form Input Styles */
.attachment-form input[type="text"],
.attachment-form textarea,
.attachment-form select {
  @apply w-full rounded-md border-px border-gray-border bg-white;
}

/* Tooltip Styles */
.attachment-form .tooltip {
  @apply relative;
}

.attachment-form .tooltip .tooltiptext {
  @apply absolute invisible z-1 left-1/2 bottom-full -ml-[100px] mb-3 w-[200px] rounded bg-black opacity-0 px-4 py-3 text-white text-sm transition-opacity duration-300;
}

/* Tooltip Arrow */
.attachment-form .tooltip .tooltiptext::after {
  @apply absolute top-full left-1/2 border-6 border-transparent border-t-black;
  content: "";
}

.attachment-form .tooltip:hover .tooltiptext {
  @apply visible opacity-100;
}

/* Nested Document Input Button Styles */
[data-action="nested-document-input"] button[role="menuitem"] {
  @apply rounded-md border-px border-blue-200 bg-blue-100 py-4 text-blue font-bold;
}

[data-action="nested-document-input"] button.active {
  @apply bg-blue-200;
}

[data-action="nested-document-input"] button.save-btn {
  @apply rounded-md bg-blue px-5 py-3 text-white font-bold;
}

[data-action="nested-document-input"] button.cancel-btn {
  @apply rounded-md border-px border-blue-200 bg-blue-100 px-5 py-3 text-blue font-bold;
}

[data-action="nested-document-input"] button:disabled {
  @apply bg-gray-bg text-[#b2b2b2];
}

/* Recent Attachment Styles */
[data-action="nested-document-input"] .recent-attachment {
  @apply cursor-pointer hover:bg-yellow-100 hover:p-2 hover:my-0 hover:mx-1;
}

[data-action="nested-document-input"] input[type="text"] {
  @apply bg-white;
}

/* Progress Bar Styles */
[data-action="nested-document-input"] .progress {
  @apply h-4;
}
[data-action="nested-document-input"] .progress::-webkit-progress-bar {
  @apply bg-white;
}

/* Link Line Clamp Styles */
[data-action="nested-document-input"] .link-line-clamp {
  @apply inline-block align-middle overflow-hidden whitespace-nowrap text-ellipsis;
  direction: rtl;
}

.attachments-container:has(.new-attachments-form) .add-attachments-btn {
  @apply hidden;
}

.attachments-container
  div:has(> .add-attachments-btn):not(:has(> .show-more-btn)) {
  @apply justify-end;
}
