@layer components {
  .notification {
    @apply relative rounded mb-2 py-7 pl-8 pr-10 text-green-active border-px;

    background-color: #f2f9f6;
    border-color: rgba(5, 131, 70, 0.31);
  }

  .notification.error {
    @apply text-red border-red;

    background-color: #f9f2f2;
  }

  .notification.success {
    @apply bg-green-active text-white;
  }

  .notification > .delete {
    @apply absolute right-4 top-4;
  }

  #toast-messages {
    @apply fixed right-4 z-70 text-sm top-nav mt-1;
  }

  @screen md {
    #toast-messages {
      top: theme("inset.nav-md");
    }
  }

  .toast {
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }

  .toast.disappearing {
    transform: translateY(-100%);
    opacity: 0;
    pointer-events: none;
  }

  .toast-modal {
    @apply fixed top-16 right-16 z-10;
  }
}
