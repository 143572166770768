.loader-container {
  @apply relative flex flex-col;
  flex: 1 1;
  -ms-flex: 1 1 auto;
  /* To make sure loader doesn't overflow */
  min-height: 200px;
}

.loader-container.loading {
  @apply overflow-visible;
}

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.button.loading {
  color: transparent !important;
}

.loader-container.loading::after,
.loader,
.button.loading::after {
  @apply absolute block m-auto top-80 w-14 h-14 border-8 border-blue z-10;
  animation: spinAround 500ms infinite linear;
  left: calc(50% - theme("spacing.10"));
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
}

.button.loading::after {
  @apply pointer-events-none absolute block m-auto left-0 right-0 w-7 h-7 border-2 z-10;

  top: calc(50% - 10px);
}
