#program-dashboard .changed {
  border-radius: 12px;
}

#program-dashboard .changed.increased {
  @apply bg-green-light text-green-active;
}

#program-dashboard .changed.decreased {
  @apply bg-red-300 text-red;
}

#program-dashboard .changed.decreased > svg {
  transform: rotate(180deg);
}

#program-dashboard .assignment-status-open {
  background-color: #d5f5d3;
  color: #00763d;
}

#program-dashboard .assignment-status-assigned {
  background-color: #c0ddff;
  color: #0a60c2;
}

#program-dashboard .assignment-status-review {
  background-color: rgba(93, 56, 219, 0.2);
  color: #5d38db;
}

#program-dashboard .assignment-status-public {
  background-color: #f1f1f1;
  color: #595959;
}

#program-dashboard .tooltip {
  width: min(calc(100% + 100px), 500px);
}

#program-dashboard [data-component="activity-feed"] {
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
}

#program-dashboard
  [data-component="activity-feed"]
  .activity-line:not(:last-child) {
  @apply border-b-px border-gray-border;
}

#program-dashboard [data-component="upcoming-assignments"] .tooltip {
  @apply top-12 pt-5;
}
