.recurring-patterns input,
.recurring-patterns select {
  @apply h-8half;
  min-height: 28px;
  background: none;
}

.recurring-patterns select {
  @apply p-0 pl-3;
  width: 70px;
}

.recurring-patterns select.day-of-week {
  width: 100px;
}

.recurring-patterns input[type="date"] {
  width: 150px;
}

.recurring-patterns input[type="radio"] {
  @apply p-0 min-w-auto min-h-auto w-6 h-6 rounded-full;
}

.recurring-patterns input[type="radio"]:checked::after {
  @apply m-auto inline-block w-4 h-4 rounded-full bg-blue;
  content: "\A";
}

form[name$="-meeting"] input[type="checkbox"]:not([name="tags"]),
form[name$="-assignment"] input[type="checkbox"]:not([name="tags"]),
form[name$="-workshop"] input[type="checkbox"] {
  @apply bg-white w-7 h-7;
  min-width: 20px;
  min-height: 20px;
}

form[name$="-meeting"] input[type="checkbox"]:checked::after,
form[name$="-assignment"] input[type="checkbox"]:checked::after,
form[name$="-workshop"] input[type="checkbox"]:checked::after {
  @apply bg-blue text-white text-sm;
}

.button-toggle input.toggle[type="checkbox"] {
  width: 40px !important;
  height: 20px !important;
  background: #808080 !important;
  border-radius: 10px;
}
.button-toggle input.toggle[type="checkbox"]:checked {
  background: #0a60c2 !important;
}

.button-toggle input.toggle[type="checkbox"]::after {
  width: 12px;
  height: 12px;
  border-radius: 10px;
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  background: white;
}

.button-toggle input.toggle[type="checkbox"]:checked::after {
  content: "";
  background: white;
  left: 22px;
}
