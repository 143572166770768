.landing {
  line-height: 1.2;
}

.search-issues .typeahead {
  @apply w-full h-10;
}

.search-issues input {
  @apply min-h-0 h-9 bg-gray-100 rounded-md font-light border-2 border-gray-100 text-sm lg:text-base;
}

@screen md {
  .search-issues input {
    @apply h-10;
  }
}

.search-issues .input:active,
.search-issues .input:focus {
  @apply border-2 border-blue-light;

  box-shadow: none;
}

.search-issues .typeahead .fa-search {
  @apply absolute top-0 left-0 w-9half h-full p-4half text-gray;
}

.search-issues .search-results {
  @apply absolute top-full left-2 right-2 bg-white shadow-dropdown px-5;
}

.search-issues .search-result {
  @apply h-12 p-0 flex flex-row items-center justify-start;
}

.search-issues .search-result:not(:last-child) {
  @apply border-b-px border-gray-border;
}

.search-issues .search-result a {
  @apply flex-grow;
}

.search-issues .search-result .icon {
  @apply text-gray-icon mr-4;
}

.news-scroll-container::after {
  /* Has to have significant width to avoid Safari bug
  https://bugs.webkit.org/show_bug.cgi?id=209930 */
  @apply absolute top-0 right-0 bottom-0 h-full w-8 bg-transparent pointer-events-none;

  content: "";
  box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
  transition: box-shadow 0.3s ease-in-out;
}

.news-scroll-container.inactive::after {
  box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0);
}

.step-button {
  @apply w-12 h-12 rounded-full bg-blue text-white text-xl border-px border-transparent;

  flex-basis: auto;
  transition: all 0.3s ease-in-out;
}
.step-button:active,
.step-button:focus,
.step-button:hover {
  @apply text-red border-red;
}
.step-button[disabled] {
  @apply bg-white text-gray-border border-gray-border cursor-not-allowed;
}

.news-card {
  height: 470px;
  width: 252px;
  min-width: 252px;
}
.news-card-title-container {
  height: 57px;
  min-height: 57px;
}
.news-card-title {
  line-height: 1.15;
}
.news-card-summary {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
}

.faq-header {
  @apply inline-flex flex-row-reverse items-start;
}

/* Safari-specific selector */
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    /* Fix scroll offset */
    .faq-header a:target {
      margin-top: -64px;
      border-top: 64px solid transparent;
      background-clip: padding-box;
    }

    .faq-header a:focus {
      outline: none;
      text-decoration: underline;
    }
  }
}

.faq-header a[aria-expanded="true"] {
  @apply text-black;
}

.faq-header .toggle-chevron-up {
  @apply mr-5 mt-4 pr-4;
}

.landing-h1 {
  @apply text-xxl;
  line-height: 1.15;
}
.landing-h2 {
  @apply text-xl;
  line-height: 1.15;
}
.landing-tagline {
  @apply text-lg;
  line-height: 1.5;
}
.landing-big {
  @apply text-lg;
  line-height: 1.2;
}
.landing-body {
  @apply text-lg;
  line-height: 1.15;
}
.landing-break,
.landing-body > p:not(:last-child) {
  padding-bottom: 12px;
}
.landing-break-top {
  padding-top: 12px;
}
.landing-footer-break {
  padding-bottom: 48px;
}
.landing-header-pt {
  padding-top: 56px;
}
.landing-header-pb {
  padding-bottom: 56px;
}

@screen xsm {
  .landing-tagline {
    font-size: 27px;
  }
}

@screen md {
  .landing-h1 {
    font-size: 48px;
  }
  .landing-h2 {
    font-size: 32px;
  }
  .landing-tagline {
    font-size: 32px;
  }
  .landing-big {
    font-size: 28px;
  }
  .landing-body {
    font-size: 20px;
  }
  .landing-footer-break {
    padding-bottom: 95px;
  }
  .landing-break,
  .landing-body > p:not(:last-child) {
    padding-bottom: 28px;
  }
  .landing-break-top {
    padding-top: 28px;
  }
  .news-card {
    height: 646px;
    width: 372px;
    min-width: 372px;
  }
}

@screen lg {
  .landing-h1 {
    font-size: 58px;
  }
  .landing-h2 {
    font-size: 36px;
  }
  .landing-tagline {
    font-size: 36px;
  }
  .landing-big {
    font-size: 32px;
  }
  .landing-body {
    font-size: 24px;
  }
  .landing-footer-break {
    padding-bottom: 126px;
  }
  .landing-header-pt {
    padding-top: 74px;
  }
  .landing-header-pb {
    padding-bottom: 74px;
  }
  .landing-break,
  .landing-body > p:not(:last-child) {
    padding-bottom: 36px;
  }
  .landing-break-top {
    padding-top: 36px;
  }
}

@screen xl {
  .landing-h1 {
    font-size: 72px;
  }
  .landing-h2 {
    font-size: 44px;
  }
  .landing-tagline {
    font-size: 48px;
  }
  .landing-big {
    font-size: 42px;
  }
  .landing-body {
    font-size: 32px;
  }
  .landing-footer-break {
    padding-bottom: 126px;
  }
  .landing-header-pt {
    padding-top: 98px;
  }
  .landing-header-pb {
    padding-bottom: 98px;
  }
  .landing-break,
  .landing-body > p:not(:last-child) {
    padding-bottom: 50px;
  }
  .landing-break-top {
    padding-top: 50px;
  }
}

.landing-tagline-program-name {
  @apply inline-block px-4 bg-yellow text-black font-bold font-serif;
}

button.landing-tagline-program-name:focus {
  @apply outline-none;
}

button.landing-tagline-program-name::after {
  vertical-align: middle;
  font-size: 0.6em;
  content: "\25BC";
}

.video-container {
  @apply relative pt-9 h-0 overflow-hidden;

  padding-bottom: 56.25%;
}

.video-container iframe {
  @apply absolute top-0 left-0 w-full h-full;
}

.cta-image {
  max-height: 474px;
}
