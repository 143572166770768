/* Navigation Styles */
#nav-top {
  transition: background-color 0.2s ease-in-out;
  height: theme("inset.nav");
}

#nav-top:hover,
#nav-top.active {
  border-color: rgba(0, 0, 0, 0.2);
}

#nav-top.active {
  @apply h-10 bg-yellow;
}

#nav-top.active #nav-title {
  @apply visually-hidden;
}

#nav-top.active .site-count,
#nav-top.active .notification-count {
  @apply bg-white;
}

@screen sm {
  #nav-top #program-switcher-dropdown-menu {
    max-width: calc(100vw - 80px);
    overflow-x: auto;
  }
}

@screen md {
  #nav-top,
  #nav-top.active {
    height: theme("inset.nav-md");
  }

  #nav-top.active #nav-title {
    @apply absolute w-full h-auto overflow-visible whitespace-normal [clip:auto] [clip-path:none];
  }
}

/* Navigation Menu Styles */
#nav-menu {
  margin-top: theme("inset.nav");
}

#nav-menu [aria-current="page"] {
  @apply text-black;
}

#nav-menu [aria-current="page"]:hover {
  @apply text-red;
}

@screen md {
  #nav-menu {
    margin-top: theme("inset.nav-md");
  }
}

/* Dropdown Menu Styles */
#nav-top .dropdown-menu [role="menuitem"] {
  @apply h-10 px-6 flex items-center whitespace-nowrap text-sm;
}

#nav-top .dropdown-menu [role="menuitem"]:hover {
  @apply bg-yellow text-black;
}

#nav-top .dropdown-menu .program-menu {
  height: calc(100vh - var(--banner-height) - 128px);
}

#nav-top.active .dropdown-menu .program-menu {
  height: calc(100vh - var(--banner-height) - 80px);
}

@screen sm {
  #nav-top .dropdown-menu [role="menuitem"] {
    @apply text-base;
  }

  #nav-top .dropdown-menu .program-menu {
    @apply h-auto;
  }
}

/* Notification Styles */
.nav-notifications.has-notifications {
  @apply text-blue;
}

.nav-notifications.has-notifications::after {
  @apply w-4 h-4 bg-green-active border-green-active my-auto mx-0 absolute bottom-0 rounded-full;
  content: "";
  border-width: 0.5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}

/* Search Issues Styles */
#nav-menu .search-issues input[type="text"] {
  @apply pl-5 pr-9;
  border-width: 4px;
}

#nav-menu .search-issues input[type="text"]:hover {
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}

#nav-menu .search-issues input[type="text"]:focus {
  border-color: #c5e5ff;
}

#nav-menu .search-issues .typeahead .fa-search {
  @apply right-0 left-auto;
}

@screen md {
  #nav-menu .search-issues .typeahead {
    max-width: 586px;
  }
}

/* Search Results Styles */
#nav-menu .search-results {
  @apply z-30 px-0;
  line-height: 1;
}

#nav-menu .search-results .search-result {
  @apply px-5 py-5 min-h-12 h-auto;
}

#nav-menu .search-results .help-result {
  @apply bg-gray-bg;
}

#nav-menu .search-results .search-result:has(a:focus) {
  @apply bg-yellow-pale;
}

#nav-menu .search-results .search-result > a:focus,
#nav-menu .search-results .search-result > a:focus-visible {
  @apply outline-none;
}

@screen md {
  #nav-menu .search-issues input[type="text"] {
    @apply pl-8half;
  }
  #nav-menu .search-results .search-result {
    @apply px-8half;
  }
}

/* Active Assignments Styles */
#nav-active-assignments button::before {
  @apply text-tiny;
}

#nav-active-assignments:not(.expanded) button::before {
  content: "View all";
}

#nav-active-assignments.expanded button::before {
  content: "Hide";
}

#nav-active-assignments.expanded button > svg {
  @apply mb-[-1px] rotate-180;
}

@screen md {
  #nav-active-assignments button::before {
    @apply text-base;
  }
}

/* Main Navigation Styles */
#main-nav [data-section="emails"].active {
  @apply bg-yellow;
}

/* Utility Classes */
.max-w-xl-container-pad {
  max-width: calc(
    theme("screens.xl") + theme("spacing.8half") + theme("spacing.8half")
  );
}
