.ispin-wrapper {
  position: relative;
  display: inline-block;
  padding: 0;
  vertical-align: middle;
}

.ispin-wrapper .ispin-button {
  position: absolute;
  display: block;
  min-height: 0;
  min-width: 0;
  width: 30px;
  padding: 0;
  margin: 0;
  right: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.ispin-wrapper .ispin-button:before {
  content: "";
  display: inline-block;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

.ispin-wrapper .ispin-button.ispin-button-inc {
  top: 0;
}

.ispin-wrapper .ispin-button.ispin-button-inc:before {
  border-bottom: 5px solid black;
}

.ispin-wrapper .ispin-button.ispin-button-inc:disabled:before {
  border-bottom-color: grey;
}

.ispin-wrapper .ispin-button.ispin-button-dec {
  bottom: 0;
}

.ispin-wrapper .ispin-button.ispin-button-dec:before {
  border-top: 5px solid black;
}

.ispin-wrapper .ispin-button.ispin-button-dec:disabled:before {
  border-top-color: grey;
}
