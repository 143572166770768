@layer components {
  .dropdown {
    @apply relative;
  }
  .dropdown-menu {
    @apply hidden absolute top-full z-50;

    min-width: 192px;
  }
  .expanded .dropdown-menu {
    @apply block;
  }

  .toggle[aria-expanded="true"] [data-active="false"],
  .toggle[aria-expanded="false"] [data-active="true"] {
    @apply hidden;
  }

  .detail-card {
    @apply max-w-detail m-auto mb-5 p-5 bg-white border-px border-gray-border rounded-md;
  }

  .detail-box {
    @apply bg-gray-bg max-w-570 rounded-md flex flex-col justify-center p-6 text-center mt-4;

    min-height: 120px;
  }

  .detail-box p:not(:last-child) {
    @apply mb-6;
  }

  .topic-tag {
    @apply flex flex-row items-center justify-center px-5 h-10 mr-1 mb-1 rounded;
  }

  .link-with-icon {
    @apply flex flex-row items-start justify-start;

    line-height: 1.35;
  }

  .link-with-icon .icon {
    @apply inline-block w-8 shrink-0;
  }

  .link-with-icon .icon + span {
    @apply text-left;
  }

  [aria-expanded="false"] ~ .toggle-chevron-up,
  [aria-expanded="false"] .toggle-chevron-up {
    transform: rotate(180deg);
  }

  .thumbnail-list {
    @apply flex flex-row flex-wrap;
  }

  .thumbnail-container {
    @apply mr-5 flex-grow mb-9;

    line-height: 1.25;
    min-width: calc(50% - 12px);
    max-width: calc(50% - 12px);
  }

  @screen sm {
    .thumbnail-container {
      max-width: 170px;
      min-width: auto;
    }
  }

  .thumbnail-container .dropdown-menu {
    top: 30px;
    left: 8px;
    border-radius: 0px;
    white-space: nowrap;
  }

  .thumbnail-container .dropdown-menu.align-right {
    right: 0px;
    left: unset;
  }

  .thumbnail-container .dropdown-menu .loader-container.loading::after {
    top: 50px;
  }

  .thumbnail {
    @apply relative flex items-center justify-center overflow-hidden mb-4 rounded border-gray-border border-px;

    height: 134px;
  }

  [aria-expanded].chevron-left {
    position: relative;
  }

  [aria-expanded].chevron-left::after {
    @apply border-gray-dark;
    content: "";
    position: absolute;
    border-width: 3px;
    border-right: 0;
    border-top: 0;
    border-radius: 1px;
    height: 10px;
    width: 10px;
    margin-top: -6px;
    pointer-events: none;
    top: 50%;
    left: -20px;
    transform: rotate(-45deg);
    transform-origin: center;
  }

  [aria-expanded].chevron-left:hover::after {
    @apply border-black;
  }

  [aria-expanded="true"].chevron-left::after {
    transform: rotate(135deg);
    margin-top: -3px;
  }

  .placeholder-gray-dark::placeholder {
    @apply text-gray-dark;
  }

  .divider {
    @apply flex w-full h-px bg-gray-border;
  }

  .icon-box {
    @apply h-10 w-10 mr-4 flex items-center justify-center rounded border-px border-gray-border;
  }

  .line-height-reporting {
    line-height: 28px;
  }

  .icon {
    @apply inline-flex items-center justify-center;
  }

  .tooltip-icons {
    @apply my-0 mx-2 text-gray-icon;
  }

  .tooltip-icon {
    @apply m-2 relative;
  }

  .tooltip-icon-tooltip {
    @apply absolute p-2 right-12 rounded bg-gray-dark text-white hidden whitespace-nowrap pointer-events-none;

    font-size: 13px;
    top: -4px;
  }

  .tooltip-icon:hover .tooltip-icon-tooltip {
    @apply block;
  }

  .progress {
    @apply block bg-gray-bg border-none h-9 w-full p-0 overflow-hidden;

    appearance: none;
    border-radius: theme("spacing.9");
  }

  .progress::-webkit-progress-bar,
  .progress::-webkit-progress-value,
  .progress::-moz-progress-bar,
  .progress::-ms-fill {
    @apply bg-blue border-none;
  }

  iframe.pdf-embed {
    @apply rounded-md border-px border-gray-border;

    height: 650px;
  }

  .assignment-status {
    @apply border-px border-gray-border text-gray-dark bg-gray-bg;
  }

  .assignment-status.draft {
    @apply bg-white text-black;
  }
  .assignment-status.open {
    @apply bg-green border-transparent text-white;
  }
  .assignment-status.assigned {
    @apply text-green;

    background-color: rgba(5, 131, 70, 0.05);
    border-color: rgba(5, 131, 70, 0.31);
  }
  .assignment-status.staging {
    @apply text-blue;

    background-color: #edf5ff;
  }
  .assignment-status.closed {
    @apply text-gray-dark bg-gray-400 border-gray-400;
  }
  .assignment-status.canceled {
    @apply text-white bg-red border-red;
  }
  .assignment-status.review {
    @apply text-white;

    background-color: #46029f;
  }
  .assignment-status.published,
  .assignment-status.public {
    @apply text-white bg-gray-dark;
  }

  .assignment-status.not.published {
    font-size: 12px;
  }

  .status-block {
    @apply flex flex-row items-center justify-center h-10 rounded text-center border-px border-gray-border text-gray-dark font-bold bg-gray-bg;

    width: 100px;
    min-width: 100px;
  }
  .status-block.in-progress {
    font-size: 12px;
  }

  .scroll-y-no-scrollbar {
    @apply overflow-y-scroll overflow-scroll-touch;
  }
  .scroll-y-no-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .scroll-x-no-scrollbar {
    @apply overflow-x-scroll scrollbar-hidden overflow-scroll-touch;
  }
  .scroll-x-no-scrollbar::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .scroll-snap-x-container {
    scroll-snap-type: x mandatory;
  }
  .scroll-snap-align-start {
    scroll-snap-align: start;
  }

  .max-w-xl-container {
    @apply max-w-xl w-full mx-auto;
  }
}

.google-sign-in {
  display: flex;
  background-color: #4285f4;
  border-radius: 2px;
  height: theme("spacing.10");
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.google-sign-in .google-sign-in-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
}

.google-sign-in svg {
  height: theme("spacing.10");
  width: theme("spacing.10");
}

.google-sign-in .google-sign-in-text {
  margin-left: theme("spacing.5");
  font-family: Roboto;
  color: theme("colors.white.DEFAULT");
  font-size: 14px;
}

.google-sign-in a:hover svg #button-bg use,
.google-sign-in a:active svg #button-bg use,
.google-sign-in a:focus svg #button-bg use {
  fill: #eee;
}

.google-sign-in a:hover .google-sign-in-text,
.google-sign-in a:active .google-sign-in-text,
.google-sign-in a:focus .google-sign-in-text {
  color: #eee;
}

#tour-button {
  @apply fixed font-bold;

  height: 64px;
  width: 100px;
  bottom: -64px;
  right: 12px;
  font-size: 13px;
  transition: bottom 0.25s ease-in-out;
  z-index: 2;
}
#tour-button.loaded {
  bottom: 12px;
}

.guided-tour-step.active
  .guided-tour-step-tooltip
  .guided-tour-step-button-next,
.guided-tour-step.active
  .guided-tour-step-tooltip
  .guided-tour-step-button-previous {
  color: theme("colors.blue.DEFAULT") !important;
}

.guided-tour-step.active
  .guided-tour-step-tooltip
  .guided-tour-step-footer-bullets
  ul
  li {
  background-color: theme("colors.blue.DEFAULT") !important;
}

.guided-tour-step.active
  .guided-tour-step-tooltip
  .guided-tour-step-footer-bullets
  ul
  li.current {
  background-color: theme("colors.blue.dark") !important;
}

.guided-tour-step.active
  .guided-tour-step-tooltip
  .guided-tour-step-button-complete {
  color: theme("colors.blue.DEFAULT") !important;
}

#GuidedTourIconSet {
  display: none;
}

.tour {
  transition: transform 2s 1s ease-in-out;
  bottom: -24px;
}

.error-text .errorlist {
  color: #e11d48;
}

.error-text .errorlist a {
  @apply underline;

  color: #e11d48;
}

.error-icon .errorlist {
  @apply px-7 py-4 flex items-center rounded-md text-sm;

  background-color: #ffe4e6;
}

.error-icon .errorlist > li {
  vertical-align: text-bottom;
}

.error-icon .errorlist::before {
  @apply mr-7 text-lg font-bold;

  content: "\26A0";
}
