.badge {
  @apply relative;

  width: 74px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.15));
}

.badge-category {
  @apply absolute;

  top: 32%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  color: theme("colors.black");
}

.badge-category .is-text {
  @apply font-serif leading-none;

  font-size: 40px;
}

.badge-bg .badge-body {
  fill: theme("colors.yellow.DEFAULT");
  stroke-width: 2.25;
  stroke: theme("colors.black");
}

.badge-bg .badge-top {
  fill: theme("colors.red.DEFAULT");
}
