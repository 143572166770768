#program-create-base {
  height: calc(100vh - theme("inset.nav"));
}

@screen md {
  #program-create-base {
    height: calc(100vh - theme("inset.nav-md"));
  }
}

#program-create-base #sidebar {
  min-width: 300px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  z-index: 5;
}

#program-create-base #sidebar .step {
  line-height: 28px;
}

#program-create-base #sidebar li.bg-gray-bg > a.step,
#program-create-base #sidebar a.step[href^="#"],
#program-create-base #sidebar a.step[href^="#"]:hover {
  cursor: default;
  pointer-events: none;
  text-decoration: none;
}

#program-create-base #sidebar .step-icon {
  width: 40px;
  height: 28px;
}

#program-create-base #sidebar .step-icon > img {
  height: 90%;
}

#program-create-base #sidebar .step-status-icon {
  height: 28px;
}

#program-create-base #sidebar .step-status-icon.processing {
  animation: processing 2s infinite;
}

@keyframes processing {
  100% {
    transform: rotate(360deg);
  }
}

#program-create-base .container {
  @apply px-8 lg:px-14half my-8 overflow-y-scroll;

  height: calc(100vh - theme("inset.nav") - 48px - 80px);
}

@screen md {
  #program-create-base .container {
    height: calc(100vh - theme("inset.nav-md") - 48px - 80px);
  }
}

#program-create-base .input {
  @apply appearance-none relative inline-flex flex-row items-center justify-start shadow-none rounded min-h-50 align-top py-0 px-4 w-full bg-white border-px border-gray-inputborder text-sm text-black;
}

#program-create-base .input[readonly] {
  @apply bg-gray-bg text-gray-icon;
}

#program-create-base input::placeholder {
  @apply text-gray italic;
}

#program-create-base .setup-btn {
  min-width: 120px;
  height: 40px;
}

#program-create-base .setup-btn > a {
  display: block;
  line-height: 40px;
}

#program-create-base #modal-mount ul.publish-instructions {
  @apply pl-4;

  list-style-type: disc;
  list-style-position: inside;
}

#program-create-base .copy-btn:after {
  @apply block py-4 bg-green-dark;

  width: 100px;
  content: "Copy";
}

#program-create-base .copy-btn.copied:after {
  @apply block py-4 bg-gray-dark;

  width: 100px;
  content: "Copied";
}

#program-create ul#timezone__listbox {
  max-height: 200px;
}

#program-create .what-this:hover .headline-demo {
  display: block;
}

#program-create .headline-demo {
  position: absolute;
  top: 28px;
  right: 0px;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.6);
}

#program-create .headline-demo::before {
  position: absolute;
  top: -15px;
  right: 30px;
  content: "\25B2";
  color: rgba(0, 0, 0, 0.6);
}

#program-create .headline-demo > img {
  height: 132px;
  max-width: 428px;
}

#program-create input#id_hex_color_code {
  padding: 0px;
  width: 50px;
}

#program-create-managers .add-btn {
  width: 90px;
  height: 40px;
}

#program-create-subdomain input#id_subdomain {
  max-width: calc(100% - 180px);
}

#program-create-subdomain .error .input#id_subdomain {
  border-color: #e11d48;
}

#program-create-base .help-info {
  @apply mb-5 px-7 py-4 flex items-center rounded-md bg-blue-300 text-blue text-sm;
}

#program-create-base .dns-warning {
  @apply mb-5 px-7 py-4 flex items-center rounded-md bg-tag-bg-yellow text-gray-600 text-sm;
}

#program-create-base .dns-warning a {
  @apply underline text-gray-600;
}

#program-create-base .help-info::before,
#program-create-base .dns-warning::before {
  @apply mr-7 bg-blue text-center text-lg font-bold text-white;

  min-width: 20px;
  min-height: 20px;
  line-height: 16px;
  border-radius: 50%;
  content: "\2139";
}

#program-create-base .help-info::before {
  @apply bg-blue;
}

#program-create-base .dns-warning::before {
  @apply bg-yellow;
}

#program-create-subdomain .subdomain-status::before {
  @apply px-6 py-5 rounded-md font-bold text-sm;
}

#program-create-subdomain .subdomain-status.success::before {
  background-color: #d5f5d3;
  color: #00763d;
  content: "Connected";
}

#program-create-subdomain .subdomain-status.processing::before {
  background-color: #fde2b5;
  color: #673d01;
  content: "Setup in progress...";
}

#program-create-subdomain .subdomain-status.failed::before {
  @apply px-10;

  background-color: #fad9dd;
  color: #b2081d;
  content: "Failed";
}

#program-create-azure input#id_scraper_prefixes,
#program-create-review input#id_scraper_prefixes {
  max-width: 200px;
}

#program-create-azure .azure-variables-container,
#program-create-review .azure-variables-container,
#program-create-publish .azure-variables-container {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}

#program-create-review button.setup-btn[disabled] {
  @apply bg-gray-300 text-white;

  cursor: default;
  pointer-events: none;
}

#org-forms input.empty {
  animation: highlight-empty 3s infinite;
}

@keyframes highlight-empty {
  from {
    background-color: #fff1f3;
  }
  to {
    background-color: white;
  }
}
