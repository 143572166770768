@layer components {
  .modal {
    @apply fixed top-0 left-0 right-0 bottom-0 hidden flex-col items-center justify-center overflow-hidden z-50;
  }
  .modal.active {
    @apply flex;
  }
  .modal-background {
    @apply absolute top-0 left-0 right-0 bottom-0;

    background-color: rgba(0, 0, 0, 0.8);
  }

  .modal-header {
    @apply flex flex-row flex-wrap items-center justify-between sticky top-0 p-5 rounded-t bg-white z-1 shadow-nav;
  }

  .modal-content {
    @apply my-0 mx-4 overflow-auto relative w-full;

    max-height: 100vh;
  }

  .modal-close {
    @apply bg-none h-12 w-12 fixed top-16 right-16;

    height: 36px;
    width: 36px;
    min-height: 36px;
    min-width: 36px;
    max-height: 36px;
    max-width: 36px;
  }
  .modal-close::before {
    height: 3px;
  }
  .modal-close::after {
    width: 3px;
  }
  .modal-close:focus::before,
  .modal-close:focus::after {
    @apply bg-red;
  }

  @screen sm {
    .image-gallery-modal .thumbnail-container {
      min-width: 170px;
    }
  }
}

.image-gallery-modal .image {
  @apply p-6 flex flex-col items-start justify-center;

  height: 80%;
  max-width: 1200px;
}

.image-gallery-modal .image img {
  max-height: 100%;
  max-width: 100%;
}

.custom-occurrence-modal {
  width: min(570px, calc(100% - 40px));
}

#modal-mount {
  z-index: 100;
}
