@media print {
  nav,
  footer,
  button {
    display: none !important;
  }

  html,
  body,
  main,
  .detail,
  .detail-card {
    background-color: transparent !important;
  }

  html,
  .detail,
  #filter-mount {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  main {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  html,
  body,
  main,
  .content {
    overflow: visible;
    display: block;
  }
}
