@layer components {
  .profile-icon {
    @apply button border-none h-10 w-10 bg-gray-400 text-black;
    flex-grow: 0;
  }

  .profile-icon:hover {
    @apply text-red;
  }

  .profile-icon.copied {
    @apply bg-gray-600 text-white;
  }

  .profile-icon.copied + .tooltip {
    @apply block;
  }

  #profile .social-logo,
  .account-form .social-logo {
    @apply flex items-center justify-evenly rounded;
  }

  #profile .social-logo {
    @apply mt-6 mr-6 mb-8 h-10 w-10;
  }

  #profile .sidebar {
    margin-top: -150px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
    border: 1px solid;
    border-color: #ccc;
    border-radius: 4px;
    background-color: #fff;
  }

  #profile .badge-container {
    @apply w-1/3 my-5 px-5 mr-0;
  }

  #profile-nav {
    border-bottom: 1px solid;
    border-color: #d6d5d5;
  }

  #profile .banner {
    position: relative;
    overflow: hidden;
    height: 210px;
  }

  #profile .banner img {
    height: 210px;
    object-fit: cover;
  }

  #profile .banner-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.51) 0%,
      rgba(0, 0, 0, 0.45) 100%
    );
  }

  #profile .tab-inactive,
  #profile .tab-inactive:hover,
  #profile .tab-inactive:focus {
    @apply text-gray-600;
    cursor: default;
  }

  #profile .In-Progress-assignments {
    @apply text-orange;
  }
  #profile .In-Progress-assignments::before {
    content: "In Progress";
    display: block;
    visibility: visible;
  }

  #profile .Review-assignments {
    @apply text-purple;
  }
  #profile .Review-assignments::before {
    content: "In Review";
    display: block;
    visibility: visible;
  }

  #profile .section-box {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border: 1px solid;
    border-color: #d6d5d5;
    border-radius: 4px;
  }

  #profile a.show-option::after {
    content: "Show more";
  }

  #profile a.show-option.expanded::after {
    content: "Show less";
  }

  #profile .stats-icon {
    width: 66px;
    height: 66px;
    border-radius: 2px;
  }

  #profile .stats-img {
    max-height: 40px;
    max-width: 100%;
  }

  #profile .stats-value {
    line-height: 0.8;
  }

  #profile .performance-row {
    border-bottom: 1px solid #d6d5d5;
  }

  #profile .assignment-column {
    padding-right: 50px;
    width: calc(100% - 100px);
  }

  @media only screen and (min-width: 560px) {
    #profile .badge-container {
      @apply w-1/4;
    }
  }

  @media only screen and (min-width: 768px) {
    #profile .assignment-column {
      width: calc(100% - 300px);
    }

    #profile .badge-container {
      @apply w-1/3;
    }
  }

  #profile .status-column {
    width: 200px;
  }

  #profile .rating-column {
    width: 100px;
  }

  #profile .role-icon-link.icon-inline {
    width: 22px;
    height: 22px;
  }

  #profile .role-icon-link:hover {
    border: 3px solid transparent;
  }

  #profile .role-icon-link[href]:hover {
    @apply border-gray-400;
  }

  #profile .reporting-row,
  #profile .training-row {
    border-bottom: 1px solid;
    border-color: #d6d5d5;
  }

  #profile .reporting-row:last-child {
    border: none;
  }

  #profile .training-datetime p {
    @apply mb-3;
  }

  #profile .tag {
    border-radius: 4px;
  }

  .account-form {
    margin: 0 -12px -12px;
  }

  .account-form form {
    max-width: 1024px;
  }

  .account-form.event-reg-form form {
    max-width: none;
  }

  .account-form.event-reg-form form .field-wrapper {
    @apply w-full px-0;
  }

  .account-form .form-title {
    height: 70px;
    box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.2);
  }

  .account-form form .field-wrapper {
    @apply w-full px-5;
  }

  @media only screen and (min-width: 768px) {
    .account-form form .field-wrapper {
      @apply w-1/2;
    }
  }

  .account-form
    form#application_form.sub-form
    .field-wrapper:not(.first_name, .last_name, .zip_code, .birth_year) {
    @apply w-full;
  }

  .account-form form .field {
    @apply mb-7;
  }

  .account-form form label {
    @apply mb-4 block text-sm;
  }

  .account-form form input,
  .account-form form textarea {
    @apply px-6 border-px border-gray-border bg-white text-sm;
  }

  .account-form form input:disabled {
    @apply shadow-none border-gray-border bg-gray-bg text-gray-600 opacity-100;
  }

  .account-form form input[type="checkbox"]:checked:disabled:after,
  .account-form form input[type="radio"]:checked:disabled:after {
    @apply text-gray-600;
  }

  .account-form form textarea {
    @apply w-full;
  }

  .account-form form .help {
    @apply mt-4 italic text-gray-600;
  }

  .account-form form .error {
    @apply mb-4 text-sm;
    color: #e11d48;
  }

  .account-form form .social-account .error {
    @apply mb-0;
  }

  .account-form form .unsubscription,
  .account-form form .is_active,
  .account-form form .links,
  .account-form form .description,
  .account-form form .is_public,
  .account-form form .badge_completions {
    @apply w-full;
  }

  .account-form form .field.multi-checkbox .field-input {
    @apply flex flex-row flex-wrap;
  }

  .account-form form .field.multi-checkbox .field-input-wrapper,
  .account-form form .field.radioselect .field-input-wrapper {
    @apply flex flex-col-reverse;
  }

  .account-form form .field.multi-checkbox .help,
  .account-form form .field.radioselect .help {
    @apply mt-0 mb-4;
  }

  .account-form form .field.multi-checkbox input[type="checkbox"] {
    @apply hidden;
  }

  .account-form form .field.multi-checkbox input[type="text"] {
    @apply w-200 mb-4 px-6 border-px border-gray-border bg-white text-sm;
  }

  .account-form form .field.multi-checkbox .label.checkbox span {
    @apply block mr-4 px-6 py-4 border-px border-gray-border rounded bg-gray-bg text-gray-600;
  }

  .account-form
    form
    .field.multi-checkbox
    .label.checkbox
    input:checked:not(:disabled)
    + span {
    @apply border-blue text-blue;
  }

  .account-form
    form
    .field.multi-checkbox
    .label.checkbox
    input:checked:disabled
    + span {
    @apply border-gray-600 text-gray-600;
  }

  .account-form .field.radioselect .control {
    @apply pl-0 flex flex-col items-start;
  }

  .account-form .field.radioselect label.radio {
    @apply flex flex-row flex-wrap items-center cursor-pointer;
  }

  .account-form .field.radioselect label.radio > input {
    @apply p-0 min-w-auto min-h-auto w-6 h-6 rounded-full;
  }

  .account-form .field.radioselect label.radio + span {
    @apply -mt-4 mb-7 ml-8 w-full text-gray-600 text-base;
    line-height: 20px;
  }

  .account-form .field.radioselect label.radio > span {
    @apply ml-4 text-18;
  }

  .account-form form .field.single-other-specify > label {
    @apply font-bold;
  }

  .account-form form .field.single-other-specify label.radio > input {
    @apply rounded-md;
  }

  .account-form
    form
    .field.single-other-specify
    label.radio
    > input:checked::after {
    @apply bottom-3 m-auto inline-block text-sm font-bold;
    content: "\2713";
  }

  .account-form form .field.single-other-specify label.radio {
    @apply mb-6;
  }

  .account-form form .field.single-other-specify label.radio > span {
    @apply ml-7 text-sm;
  }

  .account-form
    form
    .field.single-other-specify
    label.radio
    > .other-specify-field {
    @apply hidden;
  }

  .account-form
    form
    .field.single-other-specify
    label.radio
    > input:checked
    ~ .other-specify-field {
    @apply flex;
  }

  .account-form form .race_ethnicity .field > label,
  .account-form form .pronouns .field > label,
  .account-form.event-reg-form form .field > label {
    @apply font-bold;
  }

  .account-form form .is_public .field label.radioselect {
    @apply mb-7 font-bold font-serif text-18;
  }

  .account-form form .is_public .field label.radio > input:checked::after {
    @apply m-auto inline-block w-4 h-4 rounded-full bg-blue;
    content: "\A";
  }

  .account-form form .unsubscription .field label {
    @apply font-bold;
  }

  .account-form form .contact_email .field .label::after,
  .account-form form .website_url .field .label::after,
  .account-form form .description .field .label::after {
    @apply text-gray-600;
    content: "(optional)";
  }

  .account-form .description .field .django-ckeditor-widget {
    @apply w-full;
  }

  .account-form form .links .field .label {
    @apply mb-4 font-bold font-serif text-18;
  }

  .account-form form .links .field input {
    @apply hidden;
  }

  .account-form form .links .field .help {
    @apply mb-9 not-italic;
  }

  .account-form form .badge_completions .field label.selectmultiple {
    @apply hidden;
  }

  #avatar-upload span[data-component="file-name"] {
    width: 250px;
  }
}
