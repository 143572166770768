@layer components {
  .help {
    @apply text-sm block mt-0;
  }

  .field-group:not(:first-child) {
    @apply mt-6;
  }

  .field {
    @apply relative min-h-40;
  }

  .required-label {
    @apply relative;
  }

  .required-label::before {
    @apply absolute;

    left: -16px;
    content: "\2731\fe0e";
  }

  #review-form legend.required-label::before {
    display: none;
  }

  form.account .label {
    @apply font-bold;
  }
}
