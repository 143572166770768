#messaging .section-row {
  @apply px-6;
  border: 1px solid #d6d5d5;
  border-radius: 8px;
  vertical-align: middle;
  cursor: pointer;
  margin-bottom: 16px;
}

#messaging .section-header .group-col {
  padding: 12px;
  margin: 0 -12px 16px;
  width: calc(100% + 24px);
}

#messaging .section-header .stats-cols {
  width: 0;
}

#messaging .enable-btn {
  position: absolute;
  top: -9px;
  left: 120px;
}

#messaging .status {
  padding: 8px 10px;
  border-radius: 4px;
}

#messaging .status.edited {
  background-color: #d5f5d3;
  color: #00763d;
}

#messaging .status.default {
  background-color: #c0ddff;
  color: #0052af;
}

#messaging .status.disabled {
  background-color: #e8e8e8;
  color: #595959;
}

#template-preview {
  height: 546px;
  overflow: hidden;
}

#template-preview .header {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px 8px 0px 0px;
}

#template-preview .template-header {
  height: 100px;
  overflow-y: scroll;
}

#template-preview .template-body {
  height: 406px;
  border: 1px solid #d6d5d5;
}

#template-preview .cke_top,
#template-preview .cke_bottom {
  display: none;
}

#template-preview .cke_contents iframe {
  background-color: #f1f1f1;
}

#template-preview #cke_id_body {
  width: 100vw !important;
}

#template-preview .cke_chrome {
  border: none;
}

#preview-template-modal {
  width: min(calc(100% - 40px), 802px);
  border: 1px solid #d6d5d5;
  border-radius: 8px 8px 0px 0px;
}

#preview-template-modal iframe,
#preview-template-modal .template-modal-footer {
  width: 100%;
}

#template-edit {
  margin: 0 -12px -12px;
  padding-bottom: 74px;
}

#template-edit .form-title {
  height: 70px;
  box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.2);
}

#template-edit label[for="id_subject"] {
  display: inline-block;
  height: 40px;
  line-height: 40px;
}

#template-edit #id_subject {
  width: calc(100% - 66px);
}

#template-edit .django-ckeditor-widget {
  width: calc(100% - 2px);
}

#template-edit .cke_inner.cke_reset {
  display: flex;
  flex-direction: column-reverse;
}

#template-edit .cke_top {
  border-top: 1px solid #d1d1d1;
  border-bottom: none;
}

#template-edit .cke_bottom {
  display: none;
}

#template-edit .cancel-btn,
#template-edit .save-btn {
  width: 95px;
  height: 40px;
}

#template-edit .send-now-btn,
#template-edit .enable-btn {
  width: 115px;
  height: 40px;
}

#template-edit .back-btn {
  width: 44px;
  padding-left: 16px;
  display: inline-block;
  line-height: 70px;
}

#template-edit .template-email-name {
  width: calc(100% - 55px);
  max-height: 60px;
  align-items: flex-start;
}

#template-edit .template-email-name > label {
  max-height: 48px;
  line-height: 24px;
}

#template-edit .template-email-name > span {
  max-height: 48px;
  line-height: 20px;
  flex: 1;
}

#confirmation-modal {
  width: min(500px, calc(100% - 40px));
}

#confirmation-modal .recipients {
  list-style-type: decimal;
  list-style-position: inside;
  max-height: min(300px, calc(100vh - 300px));
  overflow-y: scroll;
  margin: 10px;
}
