/* Avoids bug where Sign In link overflows nav container on IE 11 */
@media all and (-ms-high-contrast: none) {
  #nav-top > * {
    width: 33%;
  }
}

/* Hide IE, Edge pseudo-elements */
::-ms-clear {
  display: none;
}

::-ms-check {
  color: theme("colors.blue.DEFAULT");
  background-color: transparent;
  border-color: transparent;
}

::-ms-expand {
  background-color: transparent;
  border: 0;
}
